import EdkHostSession from './host-session'
import Logger from './logger'
import EdkUserEvent from './user-event'

/**
 * Character set to generate code verifier defined in rfc7636.
 */
const PKCE_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
/**
 * To store the OAuth client's data between websites due to redirection.
 */
const LOCALSTORAGE_ID = `edk:oauth2`
const LOCALSTORAGE_STATE = `${LOCALSTORAGE_ID}:state`
/**
 * A sensible length for the state's length, for anti-csrf.
 * PKCE 스팩에서 최소 43문자, 최대 128문자
 */
const RECOMMENDED_STATE_LENGTH = 43

export type EdkSignFlow =
  | 'SIGNUP'
  | 'SIGNUP:SELECT'
  | 'START:KAKAO'
  | 'START:NAVER'
  | 'START:APPLE'
  | 'START:ONEMIN_KAKAO'

export interface EdkSsoOption {
  clientSecret: string
  redirectUri: string
  autoRedirect: boolean
}

export interface State {
  isHTTPDecoratorActive?: boolean
  accessToken?: AccessToken
  authorizationCode?: string
  codeChallenge?: string
  codeVerifier?: string
  hasAuthCodeBeenExchangedForAccessToken?: boolean
  refreshToken?: RefreshToken
  stateQueryParam?: string
  scopes?: string[]
}

export interface RefreshToken {
  value: string
}

export interface AccessToken {
  value: string
  expiry: string
}

export interface SsoRedirectParams {
  flow: 'INVITE'
  code: string
  stage?: string
  ssoStage?: string
  scope?: string[]
  redirectUrl?: string
  isRedirect?: boolean
}

export interface AuthorizeParams {
  url: string
  stage?: string
  ssoStage?: string
  scope?: string[]
  redirectUrl?: string
  isRedirect?: boolean
  returnUrl?: string
  ext?: string
  locationType?: 'href' | 'replace'
}

export interface GoodbyeParams {
  stage?: string
  ssoStage?: string
  userId: string
  redirectUrl?: string
  locationType?: 'href' | 'replace'
}

export interface SignParams {
  stage?: string
  ssoStage?: string
  isRedirect?: boolean
  returnUrl?: string
  flow?: EdkSignFlow
  ext?: string
  locationType?: 'href' | 'replace'
}

export interface KakaoCodeSingUpResponse {
  email: string
  sso: {
    flow: 'SIGNUP'
    code: string
    redirectUri: string
  }
}

export interface KakaoCodeSingInResponse {
  email: string
  sso: {
    flow: 'SIGNIN'
    code: string
    redirectUri: null
  }
}

export type KakaoCodeResponse = KakaoCodeSingUpResponse | KakaoCodeSingInResponse

export class EdkSso {
  private readonly config: EdkSsoOption
  private readonly session: EdkHostSession
  private readonly userEvent: EdkUserEvent
  private readonly clientId: string
  private readonly stage: string
  private readonly isDebug: boolean
  private readonly logger: Logger

  constructor(params: {
    config: EdkSsoOption
    session: EdkHostSession
    userEvent: EdkUserEvent
    clientId: string
    stage: string
    isDebug: boolean
  }) {
    this.config = params.config
    this.session = params.session
    this.userEvent = params.userEvent
    this.clientId = params.clientId
    this.stage = params.stage
    this.isDebug = params.isDebug
    this.logger = new Logger({
      prefix: 'SSO',
      isDebug: this.isDebug
    })
  }

  /**
   * sso api origin 획득
   * @param stage
   */
  getSsoApiOrigin(stage: string = this.stage): string {
    switch (stage) {
      case 'local':
        return 'http://localhost:8787'
      case 'dv':
        return 'https://auth.dv.api.bznav.com'
      case 'qa':
        return 'https://auth.qa.api.bznav.com'
      case 'prod':
      default:
        return 'https://auth.api.bznav.com'
    }
  }

  /**
   * sso webapp origin 획득
   * @param stage
   */
  getSsoWebAppOrigin(stage: string = this.stage): string {
    switch (stage) {
      case 'local':
        return 'http://localhost:4600'
      case 'dv':
        return 'https://dv-sso.bznav.com'
      case 'prod':
      default:
        return 'https://sso.bznav.com'
    }
  }

  /**
   * 로그인
   */
  public async signIn({
    stage,
    ssoStage = 'prod',
    isRedirect = true,
    returnUrl,
    flow,
    ext,
    locationType = 'replace'
  }: SignParams): Promise<string> {
    const signinFlow = flow ? flow : 'SIGNIN'
    const authorizationUrl = this.getSsoApiOrigin(stage) + `/oauth/authorize?flow=${signinFlow}&`
    return this.authorize({
      url: authorizationUrl,
      ssoStage,
      isRedirect,
      returnUrl,
      ext,
      locationType
    })
  }

  /**
   * SSO 서버에 회원가입 요청
   * Fetch an authorization grant via redirection. In a sense this function
   * doesn't return because of the redirect behavior (uses `location.replace`).
   */
  public async signUp({
    stage,
    ssoStage = 'prod',
    isRedirect = true,
    flow,
    returnUrl,
    ext,
    locationType = 'replace'
  }: SignParams): Promise<string> {
    const signupFlow = flow ? flow : 'SIGNUP'
    const authorizationUrl = this.getSsoApiOrigin(stage) + `/oauth/authorize?flow=${signupFlow}&`
    return this.authorize({
      url: authorizationUrl,
      ssoStage,
      returnUrl,
      isRedirect,
      ext,
      locationType
    })
  }

  public async authorize({
    url,
    stage = this.stage,
    ssoStage,
    scope = ['sso', 'bznav'],
    isRedirect = true,
    returnUrl = window.location.href,
    ext,
    locationType = 'replace'
  }: AuthorizeParams): Promise<string> {
    const clientId = this.clientId
    const { codeChallenge, codeVerifier, codeChallengeMethod } = await this.generatePKCECodes()
    const stateQueryParam = this.generateRandomState(RECOMMENDED_STATE_LENGTH)
    const redirectUrl = this.config.redirectUri
    await this.saveState(stateQueryParam, {
      returnUrl,
      codeChallenge,
      codeVerifier,
      stateQueryParam,
      isHTTPDecoratorActive: true
    })
    this.logger.trace('Authorize', {
      clientId: this.clientId,
      returnUrl,
      stateQueryParam
    })
    ssoStage = ssoStage || 'prod'
    let replaceUrl =
      url +
      `grant_type=authorization_code&` +
      `response_type=code&` +
      `stage=${encodeURIComponent(stage)}&` +
      `sso_stage=${encodeURIComponent(ssoStage)}&` +
      `client_id=${encodeURIComponent(clientId)}&` +
      `scope=${encodeURIComponent(scope.join(' '))}&` +
      `state=${stateQueryParam}&` +
      `code_challenge=${encodeURIComponent(codeChallenge)}&` +
      `code_challenge_method=${encodeURIComponent(codeChallengeMethod)}&` +
      `redirect_uri=${encodeURIComponent(redirectUrl)}`
    if (ext) {
      replaceUrl = replaceUrl + '&ext=' + encodeURIComponent(ext)
    }
    if (isRedirect) {
      this.logger.trace('authorize', replaceUrl)
      if (locationType === 'href') {
        location.href = replaceUrl
      } else {
        location.replace(replaceUrl)
      }
    }
    return replaceUrl
  }

  public goodbye({
    stage = this.stage,
    ssoStage,
    userId,
    locationType = 'replace'
  }: GoodbyeParams): void {
    const clientId = this.clientId
    const redirectUrl = this.config.redirectUri
    const goodbyeUrl = this.getSsoApiOrigin(stage) + '/goodbye?'
    ssoStage = ssoStage || 'prod'
    const replaceUrl =
      goodbyeUrl +
      `stage=${encodeURIComponent(stage)}&` +
      `sso_stage=${encodeURIComponent(ssoStage)}&` +
      `client_id=${encodeURIComponent(clientId)}&` +
      `user_id=${encodeURIComponent(userId)}&` +
      `redirect_uri=${encodeURIComponent(redirectUrl)}`
    this.logger.trace('goodbye', replaceUrl)
    if (locationType === 'href') {
      location.href = replaceUrl
    } else {
      location.replace(replaceUrl)
    }
  }

  public async ssoRedirect({
                             flow,
                             code,
                             scope = ['sso', 'bznav'],
                             stage,
                             isRedirect = true
                           }: SsoRedirectParams): Promise<string> {
    this.logger.trace('Redirect', flow)
    stage = stage || this.stage
    const { codeChallenge, codeVerifier, codeChallengeMethod } = await this.generatePKCECodes()
    const stateQueryParam = this.generateRandomState(RECOMMENDED_STATE_LENGTH)
    const redirectUrl = this.config.redirectUri
    await this.saveState(stateQueryParam, {
      returnUrl: window.location.href,
      codeChallenge,
      codeVerifier,
      stateQueryParam,
      isHTTPDecoratorActive: true
    })
    const replaceUrl =
      this.getSsoWebAppOrigin(stage) +
      '?' +
      `flow=${encodeURIComponent(flow)}&` +
      `stage=${encodeURIComponent(stage)}&` +
      `code=${encodeURIComponent(code)}&` +
      `client_id=${encodeURIComponent(this.clientId)}&` +
      `scope=${encodeURIComponent(scope.join(' '))}&` +
      `state=${stateQueryParam}&` +
      `code_challenge=${encodeURIComponent(codeChallenge)}&` +
      `code_challenge_method=${encodeURIComponent(codeChallengeMethod)}&` +
      `redirect_uri=${encodeURIComponent(redirectUrl)}`
    if (isRedirect) {
      this.logger.trace('ssoRedirect', replaceUrl)
      location.replace(replaceUrl)
    }
    return replaceUrl
  }

  /**
   * API 토큰 요청
   * @param grantType
   */
  public async appleStart({
    token,
    stage
  }: {
    token: string
    stage?: string
  }): Promise<KakaoCodeResponse> {
    this.logger.trace('AppleStart')
    const api = this.getSsoApiOrigin(stage) + '/auth/provider/apple/start'
    const clientSecret = this.config.clientSecret
    const redirectUri = this.config.redirectUri
    const clientId = this.clientId
    const { codeChallenge, codeVerifier } = await this.generatePKCECodes()
    const stateQueryParam = this.generateRandomState(RECOMMENDED_STATE_LENGTH)
    const body = new URLSearchParams()
    const basicToken = btoa(clientId + ':' + clientSecret)
    await this.saveState(stateQueryParam, {
      returnUrl: window.location.href,
      codeChallenge,
      codeVerifier,
      stateQueryParam,
      isHTTPDecoratorActive: true
    })
    body.set('token', token)
    body.set('client_id', clientId)
    body.set('state', stateQueryParam)
    body.set('code_challenge', codeChallenge)
    body.set('code_challenge_method', 'S256')
    body.set('redirect_uri', redirectUri)
    return await EdkSso.requestSsoApi<KakaoCodeResponse>({
      url: api,
      body: body.toString(),
      headers: {
        Authorization: `Basic ${basicToken}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  /**
   * API 토큰 요청
   * @param grantType
   */
  public async kakaoStart({
    stage,
    token
  }: {
    stage?: string
    token: string
  }): Promise<KakaoCodeResponse> {
    this.logger.trace('KakaoStart')
    const api = this.getSsoApiOrigin(stage) + '/auth/provider/kakao/start'
    const { clientSecret } = this.config
    const clientId = this.clientId
    const { codeChallenge, codeVerifier } = await this.generatePKCECodes()
    const stateQueryParam = this.generateRandomState(RECOMMENDED_STATE_LENGTH)
    const body = new URLSearchParams()
    const basicToken = btoa(clientId + ':' + clientSecret)
    const redirectUrl = this.config.redirectUri
    await this.saveState(stateQueryParam, {
      returnUrl: window.location.href,
      codeChallenge,
      codeVerifier,
      stateQueryParam,
      isHTTPDecoratorActive: true
    })
    body.set('token', token)
    body.set('client_id', clientId)
    body.set('state', stateQueryParam)
    body.set('code_challenge', codeChallenge)
    body.set('code_challenge_method', 'S256')
    body.set('redirect_uri', redirectUrl)
    return await EdkSso.requestSsoApi<KakaoCodeResponse>({
      url: api,
      body: body.toString(),
      headers: {
        Authorization: `Basic ${basicToken}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  /**
   * 비즈넵 엑세스 토큰 요청
   * @param grantType
   * @param code
   * @param state
   * @param stage
   */
  public async requestToken({
    grantType = 'authorization_code',
    code,
    state,
    stage
  }: {
    grantType: 'authorization_code' | 'sync_code'
    code: string
    state?: string
    stage?: string
  }) {
    const body = new URLSearchParams()
    const {clientSecret, redirectUri} = this.config
    const clientId = this.clientId
    this.logger.trace('GetToken', code)
    const tokenUrl = this.getSsoApiOrigin(stage) + '/oauth/token'
    const basicToken = btoa(clientId + ':' + clientSecret)
    body.set('grant_type', grantType || 'authorization_code')
    body.set('code', code)
    body.set('client_id', clientId)
    if (state) {
      const stateData = await this.getState(state)
      if (!stateData) {
        throw new Error('State 정보를 찾을 수 없습니다')
      }
      body.set('code_verifier', stateData.codeVerifier)
      body.set('redirect_uri', redirectUri)
    }
    return await EdkSso.requestSsoApi<any>({
      url: tokenUrl,
      body: body.toString(),
      headers: {
        Authorization: `Basic ${basicToken}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  /**
   * API 토큰 요청
   * @param grantType
   * @param code
   */
  public async getTokenAdnSessionUpdate({
    grantType,
    code,
    state
  }: {
    grantType: 'authorization_code' | 'sync_code'
    code: string
    state?: string
  }): Promise<void> {
    const result: any = await this.requestToken({
      grantType,
      code,
      state
    })
    await this.session.addSession({
      id: result.user.id,
      name: result.user.name,
      email: result.user.email,
      avatarUrl: result.user.avatar,
      accessToken: result.access_token,
      accessTokenExpiredAt: result.expiry_date,
      refreshToken: result.refres_token,
      refreshTokenExpiredAt: new Date(),
      extId: result.user.extId
    })
    this.userEvent.emit({
      name: 'authorized',
      params: {
        method: 'sso'
      }
    })
  }


  /**
   * redirectUrl로 이후 과정를 진행하는 함수
   * 인증토큰을 받아 세션 업데이트하고, returnUrl로 리다이렉트
   * @param grantType
   * @param code
   * @param state
   */
  async authenticate({grantType, code, state}: {
    grantType: 'authorization_code' | 'sync_code',
    code: string,
    state?: string
  }) {
    const result = await this.requestToken({
      grantType,
      code,
      state
    })
    await this.session.addSession({
      id: result.user.id,
      name: result.user.name,
      email: result.user.email,
      avatarUrl: result.user.avatar,
      accessToken: result.access_token,
      accessTokenExpiredAt: result.expiry_date,
      refreshToken: result.refres_token,
      refreshTokenExpiredAt: new Date(),
      extId: result.user.extId
    })
    this.userEvent.emit({
      name: "authorized",
      params: {
        method: "sso"
      }
    })
    await this.redirectReturnUrlAndStateDelete(state);
  }

  /**
   * 재사용 토큰 요청
   * @param grantType
   */
  public async refreshToken({
    refreshToken,
    state,
    stage,
    scope = []
  }: {
    refreshToken: string
    state: string
    stage?: string
    scope: string[]
  }): Promise<void> {
    this.logger.trace('refreshToken')
    const tokenUrl = this.getSsoApiOrigin(stage) + '/oauth/token'
    const stateData = await this.getState(state)
    if (!stateData) {
      throw new Error('State 정보를 찾을 수 없습니다')
    }
    const { clientSecret } = this.config
    const clientId = this.clientId
    const body = new URLSearchParams()
    const basicToken = btoa(clientId + ':' + clientSecret)
    body.set('grant_type', 'refresh_token')
    body.set('refresh_token', refreshToken)
    body.set('scope', scope.join(' '))
    body.set('client_id', clientId)
    const result: any = await EdkSso.requestSsoApi({
      url: tokenUrl,
      body: body.toString(),
      headers: {
        Authorization: `Basic ${basicToken}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    await this.session.addSession({
      id: result.user.id,
      name: result.user.name,
      email: result.user.email,
      avatarUrl: result.user.avatar,
      accessToken: result.access_token,
      accessTokenExpiredAt: result.expiry_date,
      refreshToken: result.refres_token,
      refreshTokenExpiredAt: new Date(),
      extId: result.user.extId
    })
    await this.deleteState(state)
    this.logger.trace('refreshToken', stateData.returnUrl)
    location.replace(stateData.returnUrl)
  }

  /**
   * state 정보
   * @param state
   */
  public async getState(state: string) {
    const resultString = sessionStorage.getItem(LOCALSTORAGE_STATE + ':' + state)
    const data = resultString ? JSON.parse(resultString) : null
    this.logger.trace('getState', data)
    return data
  }

  /**
   * state 정보 저장
   * @param state
   * @param data
   */
  public async saveState(
    state: string,
    data: {
      returnUrl?: string
      codeChallenge: string
      codeVerifier: string
      stateQueryParam: string
      isHTTPDecoratorActive: boolean
    }
  ) {
    sessionStorage.setItem(LOCALSTORAGE_STATE + ':' + state, JSON.stringify(data))
    return true
  }

  /**
   * state 정보 삭제
   * @param state
   */
  public async deleteState(state: string) {
    this.logger.trace('deleteState', state)
    sessionStorage.removeItem(LOCALSTORAGE_STATE + ':' + state)
    return true
  }

  /**
   * State 정보로 이전 URL로 돌아가고 종료
   * @param state
   */
  public async redirectReturnUrlAndStateDelete(state?: string) {
    if (state) {
      const stateData = await this.getState(state)
      if (stateData) {
        this.logger.trace('PdkOAuthService.redirectStateReturnUrl', stateData.returnUrl)
        await this.deleteState(state)
        location.replace(stateData.returnUrl)
      }
    }
  }

  /**
   * Implements *base64url-encode* (RFC 4648 § 5) without padding, which is NOT
   * the same as regular base64 encoding.
   */
  private static base64urlEncode(value: string): string {
    let base64 = btoa(value)
    base64 = base64.replace(/\+/g, '-')
    base64 = base64.replace(/\//g, '_')
    base64 = base64.replace(/=/g, '')
    return base64
  }

  /**
   * 클라이언트 인가 코드 탈취 공격을 방지하기 위한 PKCE 토큰 생성
   * Generates a code_verifier and code_challenge, as specified in rfc7636.
   */
  private generatePKCECodes(): PromiseLike<{
    codeChallenge: string
    codeVerifier: string
    codeChallengeMethod: string
  }> {
    /**
     * The maximum length for a code verifier for the best security we can offer.
     * Please note the NOTE section of RFC 7636 § 4.1 - the length must be >= 43,
     * but <= 128, **after** base64 url encoding. This means 32 code verifier bytes
     * encoded will be 43 bytes, or 96 bytes encoded will be 128 bytes. So 96 bytes
     * is the highest valid value that can be used.
     */
    const RECOMMENDED_CODE_VERIFIER_LENGTH = 96
    const output = new Uint32Array(RECOMMENDED_CODE_VERIFIER_LENGTH)
    crypto.getRandomValues(output)
    const codeVerifier = EdkSso.base64urlEncode(
      Array.from(output)
        .map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length])
        .join('')
    )
    /**
     * crypto.subtle is supposed to be undefined in insecure contexts
     * https://stackoverflow.com/questions/46468104/how-to-use-subtlecrypto-in-chrome-window-crypto-subtle-is-undefined
     */
    if (crypto.subtle === undefined) {
      throw new Error('변조된 클라이언트으로 접근하셨습니다.')
    }
    return crypto.subtle
      .digest('SHA-256', new TextEncoder().encode(codeVerifier))
      .then((buffer: ArrayBuffer) => {
        const hash = new Uint8Array(buffer)
        const hashLength = hash.byteLength
        let binary = ''
        for (let i = 0; i < hashLength; i++) {
          binary += String.fromCharCode(hash[i])
        }
        return binary
      })
      .then(EdkSso.base64urlEncode)
      .then((codeChallenge: string) => ({
        codeChallenge,
        codeVerifier,
        codeChallengeMethod: 'S256'
      }))
  }

  /**
   * CSRF 공격 장지를 위한 state 파라미터 추가
   * Generates random state to be passed for anti-csrf.
   */
  private generateRandomState(lengthOfState: number): string {
    const output = new Uint32Array(lengthOfState)
    crypto.getRandomValues(output)
    return Array.from(output)
      .map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length])
      .join('')
  }

  /**
   * SSO API 서버 요청
   */
  private static async requestSsoApi<T>(options: {
    url: string
    body: string
    headers: object
  }): Promise<T> {
    const result = await window.fetch(options.url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        ...options.headers
      },
      body: options.body
    })
    return result.json()
  }
}
