export interface EdkHostSessionInfo {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  syncToken?: string;
  syncId?: string;
  syncOrgs?: {
    orgSyncId: string,
    orgBizNo: string,
    orgAlias: string,
    syncStatus: string
  }[];
  syncTokenExpiredAt?: Date;
  accessToken?: string;
  accessTokenExpiredAt?: Date;
  refreshToken?: string;
  refreshTokenExpiredAt?: Date;
  extId: string;
}

interface EdkHostSessionConstructorParams {
  storage: Storage
  stage: string
  isDebug: boolean
}

export default class EdkHostSession {
  private readonly _isDebug: boolean
  private readonly _stageKey: string
  private readonly _storageKey: string
  private readonly _storageSessionKey: string
  private readonly _storage: Storage

  constructor({
    storage,
    stage,
    isDebug
  }: EdkHostSessionConstructorParams) {
    this._isDebug = isDebug
    this._stageKey = 'edk:stage'
    this._storageKey = 'edk:' + stage
    this._storageSessionKey = this._storageKey + ':session'
    this._storage = storage
  }

  /**
   * 세션 추가
   */
  addSession(session: EdkHostSessionInfo) {
    this._storage.setItem(this._storageSessionKey, JSON.stringify(session))
  }

  /**
   * 세션 업데이트
   */
  updateSession(session: EdkHostSessionInfo) {
    const prevSession = this.getSession()
    if (!prevSession) {
      throw new Error('세션이 존재하지 않습니다.')
    }
    if (prevSession.syncId !== session.syncId) {
      this.removeSession()
      return null
    }
    this._storage.setItem(this._storageSessionKey, JSON.stringify({
      ...prevSession,
      ...session
    }))
  }

  /**
   * 세션 정보
   */
  getSession(): EdkHostSessionInfo | null {
    const result = this._storage.getItem(this._storageSessionKey)
    if (result === null) {
      return null
    }
    return JSON.parse(result) as EdkHostSessionInfo
  }

  /**
   * 세션 삭제
   */
  removeSession() {
    this._storage.removeItem(this._storageSessionKey)
  }

  /**
   * 세션 비우기
   */
  reset() {
    for (let i = 0; i < this._storage.length; i++) {
      const key = this._storage.key(i) || ''
      if (key.indexOf(this._storageSessionKey) === 0) {
        this._storage.removeItem(key)
      }
    }
  }
}
