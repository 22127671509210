import { EdkClientEvent, EdkEvent, EdkEventListener, EdkEventSendTrigger } from './event'
import EdkHost from './host'
import { retryAndTimeout, uuidV4 } from './util'

type EdkIframeComponentElement = {
  id: string
  isReady: boolean
  isHandShake: boolean
  $spinner: HTMLDivElement
  $iframe: HTMLIFrameElement
  $container: HTMLElement
}

export default class EdkIframeComponent {
  public component: EdkIframeComponentElement | undefined
  private _eventListen = false
  private _embedDomId = 'em_embed'
  private _iframeTargetOrigin = '*'
  private readonly _handshakeId: string
  private readonly edkIframeEventListener: EventListener

  get hanshakeId() {
    return this._handshakeId
  }

  constructor(
    private host: EdkHost,
    private id: string,
    private options: {
      url: string
      eventListener?: EdkEventListener
      dom?: HTMLElement
      onReady?: (component: EdkIframeComponentElement) => void
      onSendEvent?: EdkEventSendTrigger
      onHandShake?: (component: EdkIframeComponentElement) => void
    }
  ) {
    this._handshakeId = uuidV4()
    this.appendComponent({
      url: this.options.url,
      dom: this.options.dom
    })
    this.edkIframeEventListener = this.createEdkClientEventListener()
    window.addEventListener('message', this.edkIframeEventListener, false)
    this._eventListen = true
    this._logger('CONSTRUCTOR', {
      id: this.id
    })
  }

  /**
   * 서로 메시지 통신을 확인한다.
   * - 1초 간격으로 3번 응답을 확인한다.
   * - 연결에 실패하면 이벤트 리스너는 삭제된다.
   */
  handShake() {
    const component = this.component
    if (!component) {
      throw new Error('컨포넌트가 없습니다')
    }
    this.emitEvent({
      id: this.id,
      clientId: this.host.clientId,
      type: 'host',
      action: 'handshake',
      message: '메시지 핸드쉐이크 요청',
      data: {
        id: this.id,
        handshakeId: this._handshakeId,
        hostEdkVersion: this.host.info.version,
        session: this.host.session.getSession()
      }
    })
    retryAndTimeout({
      worker: () =>
        new Promise((resolve, reject) => {
          if (this.component?.isHandShake) {
            resolve()
          } else {
            this._logger('HANDSHAKE:RETRY')
            reject('HandShake timeOut')
          }
        }),
      waitTimeout: 1000,
      retry: 3
    }).catch(() => {
      this._logger('HANDSHAKE:FAIL')
      this.destroy()
    })
  }

  /**
   * IFrame을 추가
   * 컨포넌트를 초기화하고, edkIframeEventListener 참조 할 수 있는 초기화를 진행한다.
   * @private
   */
  appendComponent({ id, url, dom }: { id?: string; url: string; dom?: HTMLElement }) {
    if (id && id !== this.id) {
      this.id = id;
    }
    if (this.component && this.component.$container) {
      this.component.$container.innerHTML = ''
    }
    const iframe = EdkIframeComponent.createIframeElement(this.id, url)
    const spinner = EdkIframeComponent.createSpinnerElement(this.id)
    const container = dom ? this.selectElement(dom) : this.selectElement(this.options.dom)
    container.innerHTML = ''
    container.appendChild(spinner)
    container.appendChild(iframe)
    if (!this._eventListen) {
      window.addEventListener('message', this.edkIframeEventListener, false)
    }
    this.component = {
      id: this.id,
      isReady: false,
      isHandShake: false,
      $container: container,
      $spinner: spinner,
      $iframe: iframe
    }
    this._logger('APPEND_IFRAME', {
      component: this.component
    })
    return this.component
  }

  /**
   * Iframe 이벤트를 응답받는 리스너 생성
   * @private
   */
  private createEdkClientEventListener() {
    return (event: any) => {
      const edkEvent = event.data as EdkEvent
      if (edkEvent && edkEvent.type === 'client' && edkEvent.id === this.id) {
        const component = this.component
        if (!component) {
          throw new Error('EdkIframe: 컨포넌트를 찾을 수 없습니다')
        }
        const action = edkEvent.action ? ':' + edkEvent.action.toUpperCase() : ''
        this._logger(`LISTEN:EVENT${action}`, edkEvent)
        /** 준비가 되지 않은 경우에만 동작 */
        if (edkEvent.action === 'ready') {
          this.handShake()
          this._logger('READY')
          component.isReady = true
          component.$spinner.style.display = 'none'
          if (this.options.onReady) {
            this.options.onReady(component)
          }
        }
        /** handshake 완료된 이벤트만 동작 */
        if (edkEvent.handshakeId === this.hanshakeId) {
          if (edkEvent.action === 'handshake') {
            component.isHandShake = true
            this._logger('HANDSHAKE:SUCCESS')
            if (this.options.onHandShake) {
              this.options.onHandShake(component)
            }
          }
          if (edkEvent.action === 'replace') {
            this._logger('REPLACE', edkEvent.data, this.options)
            this.appendComponent({
              id: edkEvent.data.id,
              url: edkEvent.data.url,
              dom: this.options.dom
            })
          }
          if (edkEvent.action === 'link') {
            this._logger('LINK', edkEvent.data)
            this.destroy()
            window.location.href = edkEvent.data.url
          }
          if (edkEvent.action === 'close') {
            this._logger('CLOSE')
            this.destroy()
          }
          if (this.options.eventListener) {
            this.options.eventListener(edkEvent)
          }
        }
      }
    }
  }

  /**
   * client 에게 이벤트 전송
   * @param data
   */
  sendEvent(data: EdkClientEvent) {
    if (!this.component?.isReady) {
      throw new Error('EDK: 클라이언트가 준비 되지 않았습니다.')
    }
    if (this.options.onSendEvent) {
      this.options.onSendEvent(data)
    }
    this.emitEvent(data)
  }

  /**
   * component 삭제하고 등록된 이벤트 리스너를 삭제한다.
   */
  destroy() {
    window.removeEventListener('message', this.edkIframeEventListener, false)
    if (this.component) {
      this.component.$container.innerHTML = ''
    }
    this.component = undefined
    this._eventListen = false
    this._logger(`DESTROY`, {
      component: this.component
    })
  }

  /**
   * em_embed 요소를 선택 안에 있는 모든 요소를 삭제하고 DOM을 반환한다.
   * @private
   */
  private selectElement(dom?: HTMLElement): HTMLElement {
    const $emEmbed = dom || document.getElementById(this._embedDomId)
    if (!$emEmbed) {
      throw new Error('EDK: append 대상 DOM을 찾을 수 없습니다.')
    }
    return $emEmbed
  }

  /**
   * event 전송
   * @private
   * @param data
   */
  private emitEvent(data: EdkEvent) {
    if (this.id === data.id && this.component?.$iframe.contentWindow) {
      this.component?.$iframe.contentWindow.postMessage(data, this._iframeTargetOrigin)
      if (this.options.eventListener) {
        this.options.eventListener(data)
      }
    }
    this._logger(`REQUEST:EVENT`, data)
  }

  /**
   * 로그
   * @param key
   * @param data
   * @private
   */
  private _logger(key: string, ...data: any[]) {
    if (this.host.isDebug) {
      // eslint-disable-next-line no-console
      console.log(`[EDK:IFRAME:${this.id.toUpperCase()}:${key}] `, ...data)
    }
  }

  /**
   * spinner dom 생성
   * @private
   */
  private static createSpinnerElement(id: string) {
    const spinner = document.createElement('div')
    spinner.setAttribute('class', `edk-spinner edk-spinner-${id}`)
    spinner.appendChild(document.createElement('div'))
    spinner.appendChild(document.createElement('div'))
    spinner.appendChild(document.createElement('div'))
    spinner.appendChild(document.createElement('div'))
    const spinnerContainer = document.createElement('div')
    spinnerContainer.style.position = 'absolute'
    spinnerContainer.style.background = 'white'
    spinnerContainer.style.width = '100%'
    spinnerContainer.style.height = '100%'
    spinnerContainer.appendChild(spinner)
    return spinnerContainer
  }

  /**
   * iframe dom 생성
   * @param id
   * @param url
   * @private
   */
  private static createIframeElement(id: string, url: string) {
    const iframe = document.createElement('iframe')
    iframe.setAttribute('class', `edk-iframe edk-iframe-${id}`)
    iframe.setAttribute('id', id)
    iframe.setAttribute('name', id)
    iframe.setAttribute('frameborder', 'none')
    iframe.setAttribute(
      'sandbox',
      'allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation'
    )
    iframe.setAttribute('allow', 'clipboard-write; *')
    iframe.src = url
    iframe.style.position = 'relative'
    iframe.style.overflow = 'hidden'
    iframe.style.width = '100%'
    iframe.style.height = '100%'
    return iframe
  }
}
